/*!
 * Start Bootstrap - Landing Page Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body,
html {
  width: 100%;
  height: 100%;
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.topnav {
  font-size: 14px;
  color: #efefef;
}

.intro-header {
  padding-top: 50px; /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
  padding-bottom: 50px;
  text-align: center;
  color: #f8f8f8;
  background: url('https://d1x0oaju7ljwc0.cloudfront.net/lib/img/marketing-splash-3.png') no-repeat center center;
  background-size: cover;
}

.intro-message {
  position: relative;
  padding-top: 20%;
  padding-bottom: 20%;
}

.intro-message > h1 {
  margin: 0;
  text-shadow: 0px 0px 8px #333;
  font-size: 5em;
}

.intro-divider {
  max-width: 600px;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.intro-message > h2 {
  font-weight: normal;
  text-shadow: 0px 0px 3px #333;
}

@media (max-width: 767px) {
  .intro-message {
    padding-bottom: 15%;
  }

  .intro-message > h1 {
    font-size: 3em;
  }

  .intro-divider {
    width: 100%;
  }
}

footer {
  padding: 1.5em;
  background-color: #f8f8f8;
}
